<template>
  <div class="main">
    <div class="dashboard content-body">
      <a-row>
        <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <div class="widget">
            <div class="no_of_candidate">
              <a-row>
                <a-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
                  <img
                    src="./../../../assets/images/clientCredit.svg"
                    alt="No of Candidate"
                    srcset=""
                  />
                </a-col>
                <a-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18">
                  <div
                    class="value"
                    v-if="statistic?.subscriptionType != 'Subscription'"
                  >
                    Total Credit<span>{{ statistic?.quantity }}</span>
                  </div>
                  <div
                    class="value"
                    v-if="statistic?.subscriptionType == 'Subscription'"
                  >
                    Total Subscription<span>{{ statistic?.quantity }}</span>
                  </div>
                </a-col>
              </a-row>
              <div class="subHead">
                <div class="contents">
                  <p v-if="statistic?.subscriptionType != 'Subscription'">
                    Credit Available
                    <span>{{ statistic?.availableCredit }}</span>
                  </p>
                  <p v-if="statistic?.subscriptionType == 'Subscription'">
                    Subscription Available
                    <span>{{ statistic?.availableCredit }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <div class="widget">
            <div class="no_of_client">
              <a-row>
                <a-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
                  <img
                    src="./../../../assets/images/clientPeople.svg"
                    alt="No of Client"
                    srcset=""
                  />
                </a-col>
                <a-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18">
                  <div class="value">
                    Total Candidate
                    <span>{{ statistic?.totalCandidates }}</span>
                  </div>
                </a-col>
              </a-row>
              <div class="subHead">
                <div class="contents">
                  <p>
                    Active Candidate
                    <span>{{ statistic?.activeCandidates }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <div class="widget">
            <div class="no_of_course">
              <a-row>
                <a-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
                  <img
                    src="./../../../assets/images/clientFile.svg"
                    alt="No of Course"
                    srcset=""
                  />
                </a-col>
                <a-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18">
                  <div class="value">
                    Total Courses<span>{{ statistic?.totalCourse }}</span>
                  </div>
                </a-col>
              </a-row>
              <div class="subHead">
                <div class="contents">
                  <p>
                    Unpublished Courses
                    <span>{{ statistic?.unpublishedCourse }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row class="video-section" :gutter="[8,8]">
        <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" v-if="youtube.url1">
          <div class="videoCnt">
            <div class="visitors_analysis">
              <iframe width="100%" height="315" :src="youtube.url1 + '?controls=0'" frameborder="0" allow="accelerometer; autoplay; modestbranding; encrypted-media; gyroscope; picture-in-picture" allowfullscreen autohide=2  showinfo= 0></iframe>
              <div class="overlayCnt"></div>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" v-if="youtube.url2">
          <div class="videoCnt">
            <div class="visitors_analysis">
              <iframe width="100%" height="315" :src="youtube.url2 + '?controls=0'" frameborder="0" allow="accelerometer; autoplay; modestbranding; encrypted-media; gyroscope; picture-in-picture" allowfullscreen autohide=2  showinfo= 0></iframe>
              <div class="overlayCnt"></div>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" v-if="youtube.url3">
          <div class="videoCnt">
            <div class="visitors_analysis">
              <iframe width="100%" height="315" :src="youtube.url3 + '?controls=0'" frameborder="0" allow="accelerometer; autoplay; modestbranding; encrypted-media; gyroscope; picture-in-picture" allowfullscreen autohide=2  showinfo= 0></iframe>
              <div class="overlayCnt"></div>
            </div>
          </div>
        </a-col>
      </a-row>
      <div class="courseLibrary">
        <div class="header">
          <p>Courses library</p>
        </div>
        <div class="bodyCnt">
          <div
            class="course-details"
            v-for="course in courses"
            :key="course._id"
          >
            <div class="contents">
              <div class="cImg">
                <img :src="course.image" alt="" srcset="" />
              </div>
              <div class="chapters">
                <div class="title">
                  <div class="category">
                    <img
                      src="./../../../assets/images/archive.svg"
                      alt="category"
                      srcset=""
                    />
                    <span class="txt"> {{ course.category.title }}</span>
                  </div>
                  <div class="cours">
                    {{ course.title }}
                  </div>
                </div>
                <div class="cnts">
                  <p>
                    <span class="leftCnt">Course assessor :</span
                    ><span class="rightCnt">{{ course.assessor }}</span>
                  </p>
                  <p>
                    <span class="leftCnt">Course Access level : </span
                    ><span class="rightCnt">Intermediate</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref } from 'vue'
import { notification } from 'ant-design-vue'

import profileService from '../../../services/profile'
import courseService from '../../../services/course'
import commonServices from '../../../services/common'
import appServices from '../../../services/appSettings'

export default defineComponent({
  name: 'Dashboard',
  setup (props, { emit }) {
    const statistic = ref()
    const courses = ref()
    const youtube = ref([])
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({ message, description })
    }
    const getOpenCourse = async () => {
      try {
        const clientId = commonServices.getClientId()
        const responce = await courseService.getMyCourse(clientId)
        courses.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getClientStatistic = async () => {
      try {
        const clientId = commonServices.getClientId()
        const responce = await profileService.getClientStatistics(clientId)
        statistic.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getAppSettingsBySlug = async (slug) => {
      try {
        const responce = await appServices.getBySlug(slug)
        const youtubeURLS = responce.data.value.split(',')
        let i = 1
        for (const url of youtubeURLS) {
          youtube.value['url' + i] = 'https://www.youtube.com/embed/' + url.split('/')[3]
          i++
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    onBeforeMount(() => {
      emit('updateMenu')
      getClientStatistic()
      getOpenCourse()
      getAppSettingsBySlug('client-dashboard-youtube-url')
    })
    return {
      statistic,
      courses,
      youtube
    }
  }
})
</script>

<style lang="scss">
.dashboard {
  .widget {
    padding: 20px 35px 20px 10px;
    color: #ffffff;
    .no_of_client,
    .no_of_course,
    .no_of_candidate {
      border-radius: 10px;
      padding: 15px;
      text-align: left;
      padding-bottom: 40px;
      .ant-row {
        height: 52px;
      }
      img {
        width: 50px;
      }

      .value {
        font-size: 16px;
        font-weight: 400;
        text-align: right;
        padding-right: 5px;
        color: #000;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        font-family: "TT Norms Pro Medium";
        span {
          color: #38B6FF;
          font-size: 32px;
          margin: 0 0 0 5px;
        }
      }

      .text {
        font-weight: bold;
        margin-top: 5px;
      }

      .time {
        font-size: 12px;
      }
      .subHead {
        display: flex;
        justify-content: flex-end;
        float: right;
        margin: 10px 0 0 0;
        .contents {
          border-radius: 43px;
          width: fit-content;
          padding: 0 15px;
          margin: 0 -35px 0 0;
          p {
            color: #ffffff;
            font-size: 16px;
            margin: 0;
            display: flex;
            align-items: center;
            span {
              font-size: 30px;
              margin: 0 0 0 10px;
            }
          }
        }
      }
    }
    .no_of_client {
      background: #fff;
      .subHead {
        .contents {
          background-color: #38B6FF;
          box-shadow: 0px 0px 40px #38B6FF80;
        }
      }
    }
    .no_of_course {
      background: #fff;
      .value {
        span {
          color: #f6596f;
        }
      }
      .subHead {
        .contents {
          background-color: #f6586f;
          box-shadow: 0px 0px 40px #f6596f80;
        }
      }
    }
    .no_of_candidate {
      background: #fff;
      .value {
        span {
          color: #11cdef;
        }
      }
      .subHead {
        .contents {
          background-color: #11cdef;
          box-shadow: 0px 0px 40px #11cdef80;
        }
      }
    }
  }
  .video-section {
    margin: 20px 0 0 0 !important;
    padding: 0 10px;
    .registered_users {
      padding: 0 0 0 20px;
      .graph {
        min-height: 315px;
        background: #ffffff;
        border-radius: 15px;
        box-shadow: 0px 16px 40px #e2e9ee80;
        img {
          border-radius: 15px;
        }
      }
    }
    .videoCnt {
      border-radius: 25px;
      iframe {
        border-radius: 25px;
      }
      .overlayCnt {
        // position: absolute;
        // top: 0;
        // left: 0;
        // right: 0;
        // bottom: 0;
        // background: transparent
        //   linear-gradient(180deg, #ffffff00 0%, #38B6FF 100%) 0% 0% no-repeat
        //   padding-box;
        // border-bottom-left-radius: 25px;
        // border-bottom-right-radius: 25px;
      }
    }
  }
  .courseLibrary {
    margin: 20px 0 0 0;
    padding: 0 10px;
    .header {
      background: #fff;
      border-radius: 10px;
      height: 50px;
      text-align: left;
      display: flex;
      align-items: center;
      padding: 0 20px;
      p {
        margin: 0;
        color: #646464;
        font-size: 20px;
        font-family: "TT Norms Pro Medium";
      }
    }
    .bodyCnt {
      background: #fff;
      border-radius: 10px;
      margin: 5px 0 0 0;
      display: flex;
      padding: 30px 15px 0px;
      flex-flow: wrap;
      .course-details {
        margin: 0 15px 30px 15px;
        width: calc(20% - 30px);
        .contents {
          background: #ffffff;
          min-height: 200px;
          height: 100%;
          box-shadow: 0px 16px 40px #7090b029;
          border-radius: 10px;
          cursor: pointer;
          .cImg {
            min-height: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            max-height: 120px;
            border-radius: 10px 10px 0 0;
            overflow: hidden;
            background: #f0f2f5;
            img {
              // max-width: 150px;
              width: 100%;
            }
          }
          .chapters {
            padding: 15px;
            .title {
              font-family: "TT Norms Pro Medium";
              text-align: left;
              .category {
                color: #b5b5b5;
                font-size: 12px;
                display: flex;
                margin: 0 0 3px 0;
                img {
                  width: 15px;
                  margin: 0 5px 0 0;
                }
                .txt {
                  width: 100%;
                }
                .cIcon {
                  width: 15px;
                }
              }
              .cours {
                color: #505050;
              }
            }
            .cnts {
              margin: 5px 0 0 0;
              h2 {
                font-size: 16px;
                color: #646464;
                font-family: "TT Norms Pro Medium";
                width: 100%;
                text-align: left;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              p {
                margin: 0;
                font-size: 12px;
                display: flex;
                width: 100%;
                text-align: left;
                flex-direction: column;
                .leftCnt {
                  color: #72cf97;
                }
                .rightCnt {
                  color: #646464;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .dashboard .courseLibrary .bodyCnt .courses {
    width: 22%;
  }
}
@media only screen and (max-width: 768px) {
  .dashboard .secondSec .registered_users {
    padding: 15px 0 0 0px;
  }
  .dashboard .courseLibrary .bodyCnt .courses {
    width: 30%;
  }
}
@media only screen and (max-width: 568px) {
  .dashboard .courseLibrary .bodyCnt .courses {
    width: 46%;
  }
}
</style>
